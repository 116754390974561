import React, {useContext, useState, useEffect} from 'react'

import {DataContext} from '../../dataContext'
import getColorOverride from '../../utilities/functions'

import MajorityMeter from '../../utilities/MajorityMeter'
import LiveUpdates from '../../utilities/LiveUpdates';
import SeatMap from '../../utilities/SeatMap';
import { useParams } from 'react-router-dom';
import Declaration from '../../utilities/Declaration';
import CheckIcon from '@mui/icons-material/Check';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export default function App() {
    const {data} = useContext(DataContext)
	const [seats, setSeats] = useState([])
	const [parties, setParties] = useState([])
	const {results} = data;
	const [loaded, toggleLoaded] = useState(false)
	const [imgClass, setImgClass] = useState('fade-in-image')

	let candidates = 0

	const {prov, year} = useParams()

	let setHeight = () => {
		const leadersApp = document.getElementById('leadersApp')
		if (leadersApp) {
			if (leadersApp.offsetHeight) {
				window.sendMessage({
					height: leadersApp.offsetHeight
				})
			}
		}

	}

	useEffect(()=> {
		setTimeout(()=> {
			setImgClass('')
		}, 3000)
		if (document.getElementsByTagName("html")[0]) {
			document.getElementsByTagName("html")[0].style.backgroundColor = "#313131" ;
		}
		
		setHeight()
		setTimeout(()=>{
			setHeight()
		}, 2000)
		window.addEventListener('resize', setHeight)
		return () => {
			window.removeEventListener('resize', setHeight)
		}
	}, [])

	// useEffect(()=> {
	// 	console.log('new')

	// 	setHeight()
 	// }, [data.config])

    useEffect(()=> {
		setHeight()
        if (results.party) {
			let total = 0
			setSeats([])
			setParties([])
            results.party.partyResults.map((party, i)=>{
				for (let j=0; j<party.elected; j++) {
					total++
                    setSeats( oldArray=> [ ...oldArray, { party: party.nameShort, leading: false, id: j, color: party.color }])
				}for (let j=0; j<party.leading; j++) {
					total++
                    setSeats( oldArray=> [ ...oldArray, { party: party.nameShort, leading: true,  id: j, color: party.color }])
				}
				if (i <=results.party.partyResults.length-1 && i >4) {
					if (!party.seats) {
						return;
					}
				}
				setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, elected: party.elected, leading:party.leading, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
				
			})
			if (total < data.config.seats) {
				for (let k=0; k<data.config.seats-total; k++) {
					setSeats( oldArray=> [ ...oldArray, { party: 'none',  id: k, color: '#e0e0e0' }])
				}
			}
		}
	}, [results])

	const getLeaders = (partyName) => {
		if (data.leaders) {
			let leaderData = data.leaders.parties.find(party=>{
				return party.partyNameShort === partyName
			});
			return leaderData
		} else return null
	}
	const getColor = (party) => {
		let colors = {
			color: party.color, 
			text: 'white'
		}
		if (data.config.parties) {
			let parsedParty = data.config.parties.find(el=>el.nameShort === party.partyName)
			colors.color = parsedParty.chiclet
			colors.text = parsedParty.text
		}
		return colors
	}

    const Party = (props) => {
		const {party, id} = props; 
		const leader = getLeaders(party.partyName)
		// const colors = getColorOverride(party.partyName, `${prov}_${year}`) 
		const colors = getColor(party)
		const winner = party.partyName === data.current.declaration.overallResult.partyNameShort
		return (
			// <div className={'classes.partyContainer relative basis-full flex flex-col flex-1 max-h-full self-end'} id="top-scrollbar">
				<div style={{flexGrow: id===0 ? 4 : 1}}>
				<div className={'classes.partyImgBox transition min-h-[200px] items-end md:flex relative max-w-[95%] mx-auto hidden'} id="partyImg">
					{leader.imgName && 
						<img 
							id={`leaderImg-${leader.partyNameShort}`}
							style={{opacity: loaded ? 1 : 0}}
							role="img"
							className={`${loaded ? imgClass : 'hide'} text-xs m-auto max-h-[200px] max-w-full z-50 h-auto w-auto object-contain `}
							onLoad={()=>handleLoad()}
							onError={()=>handleLoad()}
							aria-hidden={true}
							src={`data/${prov}/headshots/${leader.imgName}`} 
							alt={`Leader for ${leader.partyName} headshot`}/>
						}
				</div>
				<div key={party.id} id="partyCard" className={'justify-center relative z-50 self-stretch rounded-sm p-4 pt-6 flex flex-col items-center text-center'} style={{backgroundColor: colors ? colors.color : party.color, color: colors ? colors.text: 'white'}}>
					{winner && <div className={"bg-majority text-majorityText dark:bg-dark-majority dark:text-dark-majorityText absolute leading-5 tracking-widest py-1 px-2 -top-3 uppercase text-xs font-mono flex gap-1 items-center"}><CheckIcon className="text-lg font-bold text-majorityText dark:text-dark-majorityText" /><span>{data.current.declaration.overallResult.resultText}</span></div>}
					<div id="leaderNames">
						<div id="partyName" className={'hidden sm:block font-semibold text-xs xs:text-base md:text-xl'}>{leader.partyName}</div>
						<div id="partyName" className={'block sm:hidden font-semibold text-lg md:text-xl'}>{leader.partyNameShort}</div>
						{leader.partyLeader?
							<div id="leaderName" className="sm:block text-sm font-bold">{leader.partyLeader}</div>:
							<div id="leaderName" className="sm:block min-h-[1.25rem] leading-normal text-sm ">{" "}</div>
						}
					</div>
					<div id="leaderSeats" aria-label={`${party.seats} seats won`} className={'classes.popVote text-5xl '}> {party.seats} </div>
				</div>
			</div>
			// {/* </> */}

		);
	}

	const handleLoad =() => {
		candidates++
		if (candidates === 2) {
			toggleLoaded(true)
		}
	}

	const getSeatsReporting = () => {
		try {
			let seatsTotal = 0
			data.current.party.partyResults.map(party=>seatsTotal += party.seats)
			return seatsTotal + '/' + data.config.seats	
		} catch(e) {
			return 0 + '/' + data.config.seats
		}
	}

	const HeaderCell = (props) => {
		return <TableCell className='uppercase font-mono text-text3 text-xs tracking-widest border-none p-2' align='center'>{props.children}</TableCell>
	}
	const BodyCell = (props) => {
		return <TableCell className='uppercase font-bold text-sm md:text-lg p-2 text-dark-text border-b-[#535353]' align="center">{props.children}</TableCell>
	}

    return (
        <div 
			id="leadersApp"
			className={'md:h-full md:min-h-[700px] relative flex flex-col gap-12 bg-dark-tooltip text-dark-text'}>
			{data.error && <div className="p-2 mx-auto">Election Data Currently Unavailable</div>}
			{data.config && <div className='hidden md:flex justify-between text-xs font-mono uppercase'>
					<div><span className="font-bold">{getSeatsReporting()}</span> Seats Reporting</div>
					<div><span className="font-bold">{data.current.declaration.overallResult.pollsReporting}/{data.current.declaration.overallResult.pollsTotal}</span> Polls Reporting</div>
			</div>}
			{data.config && <div className='flex flex-col'>
				<div className='flex flex-col justify-center gap-1'>
					<div className="self-center font-mono uppercase text-xs tracking-wide"><span className='font-bold'>{data.config.majority}</span> seats needed for majority</div>
					<div className="self-center text-4xl font-bold text-center">{data.config.title}</div>
					<div className="self-center"><LiveUpdates time={results.party.generated} /></div>
					{data.config && <div className='flex md:hidden justify-between text-xs font-mono uppercase'>
					<div><span className="font-bold">{getSeatsReporting()}</span> Seats Reporting</div>
					<div><span className="font-bold">{data.current.declaration.overallResult.pollsReporting}/{data.current.declaration.overallResult.pollsTotal}</span> Polls Reporting</div>
				</div>}
				</div>
				
			</div>}
			
			{(results.party && seats && parties && !data.error) && 
			<div className={'classes.main flex flex-col gap-8 relative'}>
				<div 
					// style={{gridTemplateColumns: `repeat(${parties.length}, 1fr)`, gridTemplateRows: 'auto max-content'}} 
					// className={'classes.partyMap grid content-evenly grid-flow-col gap-x-1 xs:gap-x2 overflow-y-hidden overflow-x-auto'}
					className={'flex gap-4 '}
					>
					{parties.sort((a,b)=>{
						if (a.seats > b.seats) {
							return -1
						} else if (a.seats < b.seats) {
							return 1
						} else return 0
					}).map((party, i)=>{
						return <Party key={party.id} party={party} id={i}/>
					})}
				</div>
				<div>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow className='uppercase font-mono'>
									<HeaderCell>Party</HeaderCell>	
									<HeaderCell>Elected</HeaderCell>	
									<HeaderCell>Leading</HeaderCell>	
									<HeaderCell>% of total vote</HeaderCell>	
									<HeaderCell>votes</HeaderCell>	

								</TableRow>	
							</TableHead>
							<TableBody>
								{parties.map(party=>{
									if (party.partyName === 'OTH')	{
										return;
									}
									return (
										<TableRow>
											<BodyCell><div className='flex gap-2 justify-center items-center'><p className="h-4 w-4 rounded-full" style={{background: getColor(party).color}}/>{party.partyName}</div></BodyCell>
											<BodyCell>{party.elected}</BodyCell>
											<BodyCell>{party.leading}</BodyCell>
											<BodyCell>{party.votesPercent.toFixed(1)}%</BodyCell>
											<BodyCell>{party.votesTotal.toLocaleString('en')}</BodyCell>
										</TableRow>
									)
								})}	
							</TableBody>	
						</Table>	
					</TableContainer>					
				</div>
		    </div>}
        </div>
    )
}
