import React, { useContext } from 'react'
// import {makeStyles} from '@material-ui/core'
import { DataContext } from '../dataContext'

// const useStyles = {
//   container: {
//     display: 'flex', 
//     alignItems: 'center', 
//     gap: 5, 
//     color: '#333'
//   },  
//   live: {
//     backgroundColor: 'red', 
//     color: 'white', 
//     fontWeight: 'bold', 
//     textTransform: 'uppercase', 
//     padding: '0px 5px', 
//     height: 22, 
//     lineHeight: '22px', 
//     textAlign: 'center', 
//     borderRadius: 5, 
//     fontSize: 11
//   }, 
//   time: {
//     fontSize: 11, 
//   }
// }

export default function LiveUpdates({time, className}) {
  const {data} = useContext(DataContext)
  const {
    UPDATETEXT
  } = data.STRINGS

  const isLive = () => {
    let timeDate = new Date(time)
    let now = new Date()

    if (timeDate.toLocaleDateString() === now.toLocaleDateString()) {
      return true
    }
    return false
  }


  const getDate = (update) => {
		const date = new Date(update)
    const dateString = `${date.toLocaleString(`${data.lang}-us`,{year: 'numeric', month: 'long', day: 'numeric' })}` 

    return dateString + " " + date.toLocaleTimeString(`en-US`, {hour:'numeric', minute: '2-digit'})
    // return [dateArray[4], ' ', dateArray[6][1], dateArray[7][0], dateArray[8][0]].join('')
	}
  return (
    <div className={`${className} flex items-center text-xs `} id="LiveUpdate" tabIndex="0" aria-labelledby="liveUpdateText">
      {isLive() && <div className={' w-6 h-6 flex justify-center items-center'}> <div className='bg-globalred text-white rounded-full w-3 h-3' /></div>  }
      <div id="liveUpdateText" className={'classes.time uppercase font-mono tracking-wider h-6 leading-6 '}>{UPDATETEXT} {getDate(time)}</div>
    </div>
  )
}
