import React, { useContext, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import {DataContext} from '../../dataContext'
import YearButtons from '../../utilities/YearButtons'
import Declaration from '../../utilities/Declaration'

import { useParams } from 'react-router-dom'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BarChart, BarElement } from '@mui/x-charts'
import {Bar} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default function App() {

	const [hide, toggleHide] = useState('gains')
    const {data} = useContext(DataContext)
	const {results} = data
	const {prov, year}  = useParams()
	const barRef = useRef(null)
	const [vpData, setvpData]=useState(null)
	const [glData, setglData] = useState(null)
	
	function isDarkModeEnabled() {
		return document.documentElement.classList.contains('dark')
	}

	useEffect(()=> {
		if(results.party) {
			const {partyResults} = results.party
			setvpData({
				labels: partyResults.map(party=>party.nameShort),
				datasets:[
					{
						data: partyResults.map(party=>party.votesPercent), 
						backgroundColor: partyResults.map((party)=>getColor(party.nameShort)), 
						datalabels: {
							align: 'end', 
							anchor: 'end'
						}
					}, 
				]
			})
			setglData({
				labels: partyResults.map(party=>party.nameShort),
				datasets:[
					{
						data: partyResults.map(party=>party.seatChange), 
						backgroundColor: partyResults.map((party)=>getColor(party.nameShort)), 
						labels: partyResults.map(party=>party.nameShort), 
						datalabels: { 
							labels: {
								value: {
									align: (context)=>{
										if (context.dataset.data[context.dataIndex] > -1) {
											return 'end'
										} else return 'start'
									},
									anchor: (context)=>{
										if (context.dataset.data[context.dataIndex] > -1) {
											return 'end'
										} else return 'start'
									}
								}, 
								name: {
									formatter: function(value, context) {return context.chart.data.labels[context.dataIndex]}, 
									align: (context)=>{
										if (context.dataset.data[context.dataIndex] > -1) {
											return 'start'
										} else return 'end'
									},
									anchor: (context)=>{
										if (context.dataset.data[context.dataIndex] > -1) {
											return 'start'
										} else return 'end'
									}
								}
							}
						}
							
					}
				]
			})
		}
	},[results])

	const votesRef = 0

	const chartClasses = classnames(
		'p-2 text-xs rounded-none  rounded-tl-md border-b-0', 
	)
	const gainsClasses = classnames(
		'p-2 text-xs rounded-none  rounded-tr-md border-b-0', 
	)

    const CustomTooltip = ({active, payload, label}) => {
		if (active && payload && payload.length) {
			return (
				<div className="bg-white p-2 flex flex-col justify-center items-center rounded shadow-md shadow-inherit">
					<strong className="tooltip-name">{payload[0].name}</strong>
					<span>{payload[0].value.toLocaleString('en')} votes</span>
				</div>
			)
		}
		return null
	 }

	const getColor = (partyName) => {
		let barcolor = 'grey'
		try {
			if (data.config.parties) {
				let parsedParty = data.config.parties.find(el=>el.nameShort === partyName)
				if (parsedParty) {
					barcolor = parsedParty.chiclet
					return barcolor
					}
				} 
			let party = results.party.partyResults.find(el=>el.nameShort === partyName)
			if (party) {
				barcolor = party.color
			}
			return barcolor

		} catch (e) {
			return barcolor

		}
	}


    return (
		<div id="graphWidget-main" className={'classes.main flex content-center flex-col p-1 gap-4 dark:text-dark-text'}>
			<div className='flex flex-col gap-1'>
				<div className="flex space justify-between">
					<div>
						<div className='font-bold text-lg sm:text-xl'>Popular Vote</div>
						{data.config && <Declaration declaration={data.results.declaration} majority={data.config.majority} showLeading={true} />}
					</div>
					{data.config && (data.config.prevyear && <YearButtons />)}
				</div>
				<div className='relative w-full h-60 '>
				{vpData && 
						<Bar 
							data={vpData}
							width={"100%"}
							plugins={[ChartDataLabels]}
							options={{
								maintainAspectRatio: false,
								scales: {
									x: {
										max: 100, 
										min: 0, 
										ticks: {
											callback: (value) => `${value}%`, 
											color: isDarkModeEnabled() ? '#DBD9D9' :'#231F20',

											font: {
												size: 12, 
												family: 'Roboto mono'
											}
										}, 
										grid: {
											color:  isDarkModeEnabled() ? '#424242' : '#DBD9D9'
										},
										border: {
											display: false
										}
									},
									y: {
										ticks: {
											color: isDarkModeEnabled() ? '#F2F2F2' :'#231F20',

											font: {
												family: 'Roboto', 
												size: 18,
											}
										}, 
										grid: {
											display: false, 

										},
									}
									
								},
								plugins: {
									legend: {
										display: false
									}, 
									tooltip: {
										enabled: false
									},
									datalabels: {
										display: true, 
										color: isDarkModeEnabled() ? '#F2F2F2' :'#231F20',
										labels: {
											value: {
												font: {
													size: 18, 
													weight: 'bold', 
		
												},
												formatter: (value)=>`${value}%`									
											}
										}
									}
								},
								indexAxis: 'y',
							}}
						/>
					}
				</div>
			</div>
			<div className='flex flex-col gap-1'> 
				<div className="flex space justify-between">
					<div>
						<div className='font-bold text-lg sm:text-xl'>Gains and losses</div>
						{data.config && <Declaration declaration={data.results.declaration} majority={data.config.majority} showLeading={true} />}
					</div>
					{data.config && (data.config.prevyear && <YearButtons />)}
				</div>
				<div className='relative w-full h-60 '>
					{glData && 
						<Bar 
							data={glData}
							width={"100%"}
							plugins={[ChartDataLabels]}
							options={{
								maintainAspectRatio: false,
								scales: {
									x: {
										max: (context)=>{
											let datavalues = context.chart.data.datasets[0].data
											let max = Math.max.apply(null, datavalues.map(Math.abs));
											return Math.ceil((max + 10)/10)* 10
										}, 
										min: (context)=>{
											let datavalues = context.chart.data.datasets[0].data
											let min = Math.min.apply(null, datavalues);
											if (min < 0) {
												let newmin = Math.ceil((Math.abs(min) + 10)/10)* 10
												return -newmin
	
											} else return -10
										}, 
										ticks: {
											color: isDarkModeEnabled() ? '#F2F2F2' :'#231F20',

											font: {
												size: 12, 
												family: 'Roboto mono'
											}
										}, 
										grid: {
											color:  isDarkModeEnabled() ? '#424242' : '#DBD9D9'
										},
										border: {
											display: false
										}
									},
									y: {
										ticks: {
											display: false,
											color: isDarkModeEnabled() ? '#F2F2F2' :'#231F20',

											font: {
												family: 'Roboto', 
												size: 18,

											}
										}, 
										grid: {
											display: false
										}
									}
									
								},
								plugins: {
									legend: {
										display: false
									}, 
									tooltip: {
										enabled: false
									},
									datalabels: {
										labels: {
											value: {
												display: true, 
												color: isDarkModeEnabled() ? '#F2F2F2' :'#231F20',
												font: {
													size: 18, 
													weight: 'bold', 
												},
		
											}, 
											name: {
												display: true, 
												color: isDarkModeEnabled() ? '#F2F2F2' :'#231F20',
												font: {
													size: 18
												}
											}
										}
									}
									
								},
								indexAxis: 'y',

								
							}}
						/>

					}
				</div>
			</div>
		</div>

	)
}
